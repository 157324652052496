/* tslint:disable */
/* eslint-disable */
/**
 * Invictus
 * Invictus Backend API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplePaySdkSession } from '../model';
// @ts-ignore
import { Countries } from '../model';
// @ts-ignore
import { CreateFinalizationAndUpdateContactsRequest } from '../model';
// @ts-ignore
import { FinalizationOutput } from '../model';
// @ts-ignore
import { FinalizeExistingFinalizationRequest } from '../model';
// @ts-ignore
import { FinalizeTerNfcRequest } from '../model';
// @ts-ignore
import { FinalizedOrder } from '../model';
// @ts-ignore
import { GetStationAssistanceServiceUrlRequestV2 } from '../model';
// @ts-ignore
import { OutputErrorMessage } from '../model';
// @ts-ignore
import { PaymentCardBrands } from '../model';
// @ts-ignore
import { PrereserveRequest } from '../model';
// @ts-ignore
import { SplitPaymentSchedules } from '../model';
// @ts-ignore
import { StationAssistanceServiceResponse } from '../model';
/**
 * FinalizationControllerApi - axios parameter creator
 * @export
 */
export const FinalizationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create finalization and update contacts
         * @param {string} xBffKey bff key header
         * @param {CreateFinalizationAndUpdateContactsRequest} createFinalizationAndUpdateContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (xBffKey: string, createFinalizationAndUpdateContactsRequest: CreateFinalizationAndUpdateContactsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('create', 'xBffKey', xBffKey)
            // verify required parameter 'createFinalizationAndUpdateContactsRequest' is not null or undefined
            assertParamExists('create', 'createFinalizationAndUpdateContactsRequest', createFinalizationAndUpdateContactsRequest)
            const localVarPath = `/api/v1/finalizations/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFinalizationAndUpdateContactsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get countries
         * @param {string} xBffKey bff key header
         * @param {Array<string>} [deliveryModes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (xBffKey: string, deliveryModes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('getCountries', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/finalizations/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliveryModes) {
                localVarQueryParameter['deliveryModes'] = deliveryModes;
            }

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get finalization
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinalization: async (xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('getFinalization', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/finalizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payment card brands by prefix
         * @param {string} prefix 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentCardBrands: async (prefix: string, xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefix' is not null or undefined
            assertParamExists('getPaymentCardBrands', 'prefix', prefix)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('getPaymentCardBrands', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/finalizations/paymentCardBrands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get split payment schedule
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSplitPaymentSchedule: async (xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('getSplitPaymentSchedule', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/finalizations/split-payment-schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {GetStationAssistanceServiceUrlRequestV2} getStationAssistanceServiceUrlRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationAssistanceServiceUrlV2: async (xBffKey: string, getStationAssistanceServiceUrlRequestV2: GetStationAssistanceServiceUrlRequestV2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('getStationAssistanceServiceUrlV2', 'xBffKey', xBffKey)
            // verify required parameter 'getStationAssistanceServiceUrlRequestV2' is not null or undefined
            assertParamExists('getStationAssistanceServiceUrlV2', 'getStationAssistanceServiceUrlRequestV2', getStationAssistanceServiceUrlRequestV2)
            const localVarPath = `/api/v1/finalizations/station-assistance-service/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStationAssistanceServiceUrlRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Init Apple Pay session SDK
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initApplePaySdk: async (xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('initApplePaySdk', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/finalizations/init-apple-pay-sdk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary finalize finalization
         * @param {string} xBffKey bff key header
         * @param {FinalizeExistingFinalizationRequest} finalizeExistingFinalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pay: async (xBffKey: string, finalizeExistingFinalizationRequest: FinalizeExistingFinalizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('pay', 'xBffKey', xBffKey)
            // verify required parameter 'finalizeExistingFinalizationRequest' is not null or undefined
            assertParamExists('pay', 'finalizeExistingFinalizationRequest', finalizeExistingFinalizationRequest)
            const localVarPath = `/api/v1/finalizations/pay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finalizeExistingFinalizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary prereserve travel
         * @param {string} xBffKey bff key header
         * @param {PrereserveRequest} prereserveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prereserve: async (xBffKey: string, prereserveRequest: PrereserveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('prereserve', 'xBffKey', xBffKey)
            // verify required parameter 'prereserveRequest' is not null or undefined
            assertParamExists('prereserve', 'prereserveRequest', prereserveRequest)
            const localVarPath = `/api/v1/finalizations/prereserve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prereserveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resume ter finalization after loading pass
         * @param {string} terNfcFinalizationId 
         * @param {string} xBffKey bff key header
         * @param {FinalizeTerNfcRequest} finalizeTerNfcRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeFinalizationAfterLoadingPass: async (terNfcFinalizationId: string, xBffKey: string, finalizeTerNfcRequest: FinalizeTerNfcRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'terNfcFinalizationId' is not null or undefined
            assertParamExists('resumeFinalizationAfterLoadingPass', 'terNfcFinalizationId', terNfcFinalizationId)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('resumeFinalizationAfterLoadingPass', 'xBffKey', xBffKey)
            // verify required parameter 'finalizeTerNfcRequest' is not null or undefined
            assertParamExists('resumeFinalizationAfterLoadingPass', 'finalizeTerNfcRequest', finalizeTerNfcRequest)
            const localVarPath = `/api/v1/finalizations/{terNfcFinalizationId}/resume-nfc`
                .replace(`{${"terNfcFinalizationId"}}`, encodeURIComponent(String(terNfcFinalizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finalizeTerNfcRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resume finalization after 3DS
         * @param {string} finalizationId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        resumePayment: async (finalizationId: string, xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'finalizationId' is not null or undefined
            assertParamExists('resumePayment', 'finalizationId', finalizationId)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('resumePayment', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/finalizations/{finalizationId}/resume`
                .replace(`{${"finalizationId"}}`, encodeURIComponent(String(finalizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resume finalization after 3DS
         * @param {string} finalizationId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumePaymentV2: async (finalizationId: string, xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'finalizationId' is not null or undefined
            assertParamExists('resumePaymentV2', 'finalizationId', finalizationId)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('resumePaymentV2', 'xBffKey', xBffKey)
            const localVarPath = `/api/v2/finalizations/{finalizationId}/resume`
                .replace(`{${"finalizationId"}}`, encodeURIComponent(String(finalizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinalizationControllerApi - functional programming interface
 * @export
 */
export const FinalizationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinalizationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create finalization and update contacts
         * @param {string} xBffKey bff key header
         * @param {CreateFinalizationAndUpdateContactsRequest} createFinalizationAndUpdateContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(xBffKey: string, createFinalizationAndUpdateContactsRequest: CreateFinalizationAndUpdateContactsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(xBffKey, createFinalizationAndUpdateContactsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get countries
         * @param {string} xBffKey bff key header
         * @param {Array<string>} [deliveryModes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(xBffKey: string, deliveryModes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Countries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountries(xBffKey, deliveryModes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get finalization
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinalization(xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinalization(xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get payment card brands by prefix
         * @param {string} prefix 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentCardBrands(prefix: string, xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentCardBrands>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentCardBrands(prefix, xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get split payment schedule
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSplitPaymentSchedule(xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SplitPaymentSchedules>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSplitPaymentSchedule(xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {GetStationAssistanceServiceUrlRequestV2} getStationAssistanceServiceUrlRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStationAssistanceServiceUrlV2(xBffKey: string, getStationAssistanceServiceUrlRequestV2: GetStationAssistanceServiceUrlRequestV2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationAssistanceServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStationAssistanceServiceUrlV2(xBffKey, getStationAssistanceServiceUrlRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Init Apple Pay session SDK
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initApplePaySdk(xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplePaySdkSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initApplePaySdk(xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary finalize finalization
         * @param {string} xBffKey bff key header
         * @param {FinalizeExistingFinalizationRequest} finalizeExistingFinalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pay(xBffKey: string, finalizeExistingFinalizationRequest: FinalizeExistingFinalizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pay(xBffKey, finalizeExistingFinalizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary prereserve travel
         * @param {string} xBffKey bff key header
         * @param {PrereserveRequest} prereserveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prereserve(xBffKey: string, prereserveRequest: PrereserveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prereserve(xBffKey, prereserveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resume ter finalization after loading pass
         * @param {string} terNfcFinalizationId 
         * @param {string} xBffKey bff key header
         * @param {FinalizeTerNfcRequest} finalizeTerNfcRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeFinalizationAfterLoadingPass(terNfcFinalizationId: string, xBffKey: string, finalizeTerNfcRequest: FinalizeTerNfcRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizedOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeFinalizationAfterLoadingPass(terNfcFinalizationId, xBffKey, finalizeTerNfcRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resume finalization after 3DS
         * @param {string} finalizationId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async resumePayment(finalizationId: string, xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizedOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumePayment(finalizationId, xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resume finalization after 3DS
         * @param {string} finalizationId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumePaymentV2(finalizationId: string, xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinalizationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumePaymentV2(finalizationId, xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinalizationControllerApi - factory interface
 * @export
 */
export const FinalizationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinalizationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create finalization and update contacts
         * @param {string} xBffKey bff key header
         * @param {CreateFinalizationAndUpdateContactsRequest} createFinalizationAndUpdateContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(xBffKey: string, createFinalizationAndUpdateContactsRequest: CreateFinalizationAndUpdateContactsRequest, options?: any): AxiosPromise<FinalizationOutput> {
            return localVarFp.create(xBffKey, createFinalizationAndUpdateContactsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get countries
         * @param {string} xBffKey bff key header
         * @param {Array<string>} [deliveryModes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(xBffKey: string, deliveryModes?: Array<string>, options?: any): AxiosPromise<Countries> {
            return localVarFp.getCountries(xBffKey, deliveryModes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get finalization
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinalization(xBffKey: string, options?: any): AxiosPromise<FinalizationOutput> {
            return localVarFp.getFinalization(xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payment card brands by prefix
         * @param {string} prefix 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentCardBrands(prefix: string, xBffKey: string, options?: any): AxiosPromise<PaymentCardBrands> {
            return localVarFp.getPaymentCardBrands(prefix, xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get split payment schedule
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSplitPaymentSchedule(xBffKey: string, options?: any): AxiosPromise<SplitPaymentSchedules> {
            return localVarFp.getSplitPaymentSchedule(xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {GetStationAssistanceServiceUrlRequestV2} getStationAssistanceServiceUrlRequestV2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationAssistanceServiceUrlV2(xBffKey: string, getStationAssistanceServiceUrlRequestV2: GetStationAssistanceServiceUrlRequestV2, options?: any): AxiosPromise<StationAssistanceServiceResponse> {
            return localVarFp.getStationAssistanceServiceUrlV2(xBffKey, getStationAssistanceServiceUrlRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Init Apple Pay session SDK
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initApplePaySdk(xBffKey: string, options?: any): AxiosPromise<ApplePaySdkSession> {
            return localVarFp.initApplePaySdk(xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary finalize finalization
         * @param {string} xBffKey bff key header
         * @param {FinalizeExistingFinalizationRequest} finalizeExistingFinalizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pay(xBffKey: string, finalizeExistingFinalizationRequest: FinalizeExistingFinalizationRequest, options?: any): AxiosPromise<FinalizationOutput> {
            return localVarFp.pay(xBffKey, finalizeExistingFinalizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary prereserve travel
         * @param {string} xBffKey bff key header
         * @param {PrereserveRequest} prereserveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prereserve(xBffKey: string, prereserveRequest: PrereserveRequest, options?: any): AxiosPromise<FinalizationOutput> {
            return localVarFp.prereserve(xBffKey, prereserveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resume ter finalization after loading pass
         * @param {string} terNfcFinalizationId 
         * @param {string} xBffKey bff key header
         * @param {FinalizeTerNfcRequest} finalizeTerNfcRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeFinalizationAfterLoadingPass(terNfcFinalizationId: string, xBffKey: string, finalizeTerNfcRequest: FinalizeTerNfcRequest, options?: any): AxiosPromise<FinalizedOrder> {
            return localVarFp.resumeFinalizationAfterLoadingPass(terNfcFinalizationId, xBffKey, finalizeTerNfcRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resume finalization after 3DS
         * @param {string} finalizationId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        resumePayment(finalizationId: string, xBffKey: string, options?: any): AxiosPromise<FinalizedOrder> {
            return localVarFp.resumePayment(finalizationId, xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resume finalization after 3DS
         * @param {string} finalizationId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumePaymentV2(finalizationId: string, xBffKey: string, options?: any): AxiosPromise<FinalizationOutput> {
            return localVarFp.resumePaymentV2(finalizationId, xBffKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinalizationControllerApi - object-oriented interface
 * @export
 * @class FinalizationControllerApi
 * @extends {BaseAPI}
 */
export class FinalizationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create finalization and update contacts
     * @param {string} xBffKey bff key header
     * @param {CreateFinalizationAndUpdateContactsRequest} createFinalizationAndUpdateContactsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public create(xBffKey: string, createFinalizationAndUpdateContactsRequest: CreateFinalizationAndUpdateContactsRequest, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).create(xBffKey, createFinalizationAndUpdateContactsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get countries
     * @param {string} xBffKey bff key header
     * @param {Array<string>} [deliveryModes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public getCountries(xBffKey: string, deliveryModes?: Array<string>, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).getCountries(xBffKey, deliveryModes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get finalization
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public getFinalization(xBffKey: string, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).getFinalization(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payment card brands by prefix
     * @param {string} prefix 
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public getPaymentCardBrands(prefix: string, xBffKey: string, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).getPaymentCardBrands(prefix, xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get split payment schedule
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public getSplitPaymentSchedule(xBffKey: string, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).getSplitPaymentSchedule(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {GetStationAssistanceServiceUrlRequestV2} getStationAssistanceServiceUrlRequestV2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public getStationAssistanceServiceUrlV2(xBffKey: string, getStationAssistanceServiceUrlRequestV2: GetStationAssistanceServiceUrlRequestV2, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).getStationAssistanceServiceUrlV2(xBffKey, getStationAssistanceServiceUrlRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Init Apple Pay session SDK
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public initApplePaySdk(xBffKey: string, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).initApplePaySdk(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary finalize finalization
     * @param {string} xBffKey bff key header
     * @param {FinalizeExistingFinalizationRequest} finalizeExistingFinalizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public pay(xBffKey: string, finalizeExistingFinalizationRequest: FinalizeExistingFinalizationRequest, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).pay(xBffKey, finalizeExistingFinalizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary prereserve travel
     * @param {string} xBffKey bff key header
     * @param {PrereserveRequest} prereserveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public prereserve(xBffKey: string, prereserveRequest: PrereserveRequest, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).prereserve(xBffKey, prereserveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resume ter finalization after loading pass
     * @param {string} terNfcFinalizationId 
     * @param {string} xBffKey bff key header
     * @param {FinalizeTerNfcRequest} finalizeTerNfcRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public resumeFinalizationAfterLoadingPass(terNfcFinalizationId: string, xBffKey: string, finalizeTerNfcRequest: FinalizeTerNfcRequest, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).resumeFinalizationAfterLoadingPass(terNfcFinalizationId, xBffKey, finalizeTerNfcRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resume finalization after 3DS
     * @param {string} finalizationId 
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public resumePayment(finalizationId: string, xBffKey: string, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).resumePayment(finalizationId, xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resume finalization after 3DS
     * @param {string} finalizationId 
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinalizationControllerApi
     */
    public resumePaymentV2(finalizationId: string, xBffKey: string, options?: AxiosRequestConfig) {
        return FinalizationControllerApiFp(this.configuration).resumePaymentV2(finalizationId, xBffKey, options).then((request) => request(this.axios, this.basePath));
    }
}
