import { FC, HTMLAttributes } from 'react'
import { useTheme } from '@mui/material/styles'
import { ShortItineraryIconGenerate } from 'invictus-sdk-typescript'

import { useFeatures } from '@Contexts/features/FeaturesContext'

import * as styles from '../styles'

type GeneratedTransportIconProps = ShortItineraryIconGenerate & {
  isSmallGenerated?: boolean
} & HTMLAttributes<HTMLSpanElement>

const GeneratedTransportIcon: FC<GeneratedTransportIconProps> = ({
  color,
  radius,
  label,
  textColor,
  isSmallGenerated = false,
  ...rest
}) => {
  const theme = useTheme()
  const { isIvts69965FixColorContrastsEnabled } = useFeatures()

  return (
    <span
      css={
        isIvts69965FixColorContrastsEnabled
          ? styles.generatedTransportIcon(theme, color, textColor, radius, isSmallGenerated)
          : styles.generatedTransportIconLegacy(theme, color, textColor, radius, isSmallGenerated)
      }
      {...rest}
    >
      {label}
    </span>
  )
}

export default GeneratedTransportIcon
