import axios from 'axios'
import { UAParser } from 'ua-parser-js'

import { canUseDom, canUseNavigator } from './utils/canUse'
import { AuthenticationSdk } from './authentication'
import { Configuration, type MetadataContent, type TrackingRequest, type TrackingResponseSuccess } from './generated'
import { addAxiosRequestInterceptors } from './interceptors'
import { type StorageSdk } from './storage'
import {
  ClickEventId,
  getMarketLanguage,
  getTrackingDeviceType,
  getTrackingOs,
  PageName,
  PushEvent,
  SearchUsage,
  TrackingSdk,
  type UniversalSearchType,
} from './tracking'
import { DeviceSettings, User, UserSdk } from './user'

export * from './generated/model'
export * from './generated/configuration'
export * from './interceptors'
export * from './error'
export * from './authentication'
export * from './user'
export * from './storage'
export * from './tracking'
export * from './userConstants'

declare let ENV: string

declare global {
  interface Window {
    ivtsSdk: MiniSdk
    ivtsTag: {
      config: {
        clientId: string
        market: string
        bff: string | undefined
      }
      data: TrackingRequest
    }
    utag_data: { [key: string]: string | string[] } | undefined
    utag: {
      view: (datalayer: MetadataContent | undefined) => void
    }
    Didomi: {
      getUserStatus(): {
        consent_string: string
        vendors: {
          consent: {
            enabled: string[]
          }
        }
      }
      getUserConsentStatusForVendor(vendor: string): boolean
    }
    didomiEventListeners: {
      event: string
      listener: () => void
    }[]
    didomiOnReady: (() => void)[]
  }
}

export type MiniSdk = {
  trackingSdk: TrackingSdk
  storageSdk: StorageSdk
  trackingEvent?: {
    ClickEventId: {
      rechercheAutoCompletion: string
      rechercheLibre: string
      botAide: string
      rechercheExistante: string
      derniereRechercheSimple: string
      derniereRechercheComplete: string
    }
    PushEvent: {
      rechercheExistante: string
    }
    UniversalSearchType: { [key in UniversalSearchType]: key }
    SearchUsageEvent: {
      rechercheLibre: string
      rechercheAutoCompletion: string
      rechercheAutoCompletionSuggestion: string
      rechercheAutoCompletionHistorique: string
      rechercheHistoriqueRecherche: string
    }
    PageName: {
      Homepage: string
    }
  }
}

export const initMiniSdk = (configuration: Configuration, userSdk: UserSdk): MiniSdk => {
  const axiosInstance = axios.create()

  const trackingSdk = new TrackingSdk(configuration, userSdk, axiosInstance)
  const authenticationSdk = new AuthenticationSdk(trackingSdk, configuration, userSdk, axiosInstance)
  addAxiosRequestInterceptors(axiosInstance, userSdk, authenticationSdk, trackingSdk)

  return {
    trackingSdk,
    storageSdk: userSdk.storageSdk,
    trackingEvent: {
      ClickEventId: {
        rechercheAutoCompletion: ClickEventId.RechercheAutoCompletion.toString(),
        rechercheLibre: ClickEventId.RechercheLibre.toString(),
        botAide: ClickEventId.BotAide.toString(),
        rechercheExistante: ClickEventId.RechercheExistante.toString(),
        derniereRechercheSimple: ClickEventId.DerniereRechercheSimple.toString(),
        derniereRechercheComplete: ClickEventId.DerniereRechercheComplete.toString(),
      },
      PushEvent: {
        rechercheExistante: PushEvent.RechercheExistante.toString(),
      },
      UniversalSearchType: {
        suggestions: 'suggestions',
        commonSearches: 'commonSearches',
        citiesAndStations: 'citiesAndStations',
        addresses: 'addresses',
        pois: 'pois',
        trends: 'trends',
        recentSearches: 'recentSearches',
      },
      SearchUsageEvent: {
        rechercheLibre: SearchUsage.LIBRE.toString(),
        rechercheAutoCompletion: SearchUsage.AUTOCOMPLETION.toString(),
        rechercheAutoCompletionSuggestion: SearchUsage.SUGGESTION.toString(),
        rechercheAutoCompletionHistorique: SearchUsage.HISTORIQUE.toString(),
        rechercheHistoriqueRecherche: SearchUsage.HISTORIQUE_RECHERCHE.toString(),
      },
      PageName: {
        Homepage: PageName.Homepage.toString(),
      },
    },
  }
}

export const getBffBasePath = (): string => {
  const relativePath = '/bff'

  switch (window.ivtsTag?.config?.bff) {
    case undefined:
      return relativePath
    case 'production':
      return `https://www.sncf-connect.com${relativePath}`
    default:
      return `${window.ivtsTag?.config?.bff}${relativePath}`
  }
}

export const initBffSdkLibrary = async (): Promise<MiniSdk> => {
  const param = { basePath: getBffBasePath() }
  const configuration = new Configuration(param)
  let osVersion = ''
  let deviceType = ''

  if (canUseNavigator()) {
    const uaParser = new UAParser(window.navigator.userAgent)
    const os = uaParser.getOS()
    osVersion = getTrackingOs(os.name, os.version)
    deviceType = getTrackingDeviceType(uaParser.getDevice().type)
  }
  const userSdk = new UserSdk(
    new User(
      new DeviceSettings(
        window.ivtsTag?.config?.market || getMarketLanguage(),
        'web',
        window.ivtsTag?.config?.clientId,
        ENV,
        osVersion,
        deviceType
      )
    )
  )

  return initMiniSdk(configuration, userSdk)
}

const filterAsync = async <T>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => Promise<boolean>
): Promise<T[]> => {
  const filterMap = await Promise.all(array.map(callbackfn))

  return array.filter((value, index) => filterMap[index])
}

export const initDidomi = (trackingSdk: TrackingSdk): void => {
  window.didomiEventListeners = window.didomiEventListeners || []
  window.didomiEventListeners.push({
    event: 'consent.changed',
    async listener() {
      const consentString = (await window.Didomi?.getUserStatus())?.consent_string
      const vendorsWithoutPurposes = (await window.Didomi?.getUserStatus())?.vendors.consent.enabled
      const vendorsWithPurposes =
        (await filterAsync(
          vendorsWithoutPurposes,
          async (vendor: string) => (await window.Didomi?.getUserConsentStatusForVendor(vendor)) === true
        )) || []
      await trackingSdk.updateConsentData(vendorsWithPurposes, consentString)
    },
  })
  window.didomiOnReady = window.didomiOnReady || []
  window.didomiOnReady.push(async function () {
    const vendorsWithoutPurposes = (await window.Didomi?.getUserStatus())?.vendors.consent.enabled
    const vendorsWithPurposes =
      (await filterAsync(
        vendorsWithoutPurposes,
        async (vendor: string) => (await window.Didomi?.getUserConsentStatusForVendor(vendor)) === true
      )) || []
    await trackingSdk.updateMissingConsentData(vendorsWithPurposes)
  })
}

initBffSdkLibrary().then((sdk) => {
  if (canUseDom()) {
    window.ivtsSdk = sdk // expose sdk for external partners
    sdk.trackingSdk.init()
    initDidomi(sdk.trackingSdk)
    document.addEventListener('click', (evt) => sdk.trackingSdk.updateClickPosition(evt), true)
    sdk.trackingSdk.trackExternalPage(window.ivtsTag?.data).then((trackingInfo: TrackingResponseSuccess): void => {
      sdk.trackingSdk.updatePreviousPageName(trackingInfo.datalayer?.stringValues?.page_name)

      let timeout = 10

      const poll = () => {
        setTimeout(() => {
          timeout--

          if (window.utag && window.utag.view) {
            window.utag_data = trackingInfo.datalayer?.stringValues || {}
            window.utag.view(window.utag_data)
          } else if (timeout > 0) {
            poll()
          } else {
            console.error('Tealium is not loaded')
          }
        }, 500)
      }
      poll()
    })
  }
})
