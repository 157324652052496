import { meetsContrastGuidelines, parseToRgb } from 'polished'
import { ContrastScores } from 'polished/lib/types/color'

import { BLACK, WHITE } from '@Styles/constants/colors'

/**
 * @deprecated IVTS-69965
 */
export const isContrasted = (color = '#ffffff'): boolean => {
  const { red, blue, green } = parseToRgb(color.slice(0, 1) === '#' ? color : `#${color}`)

  // Get YIQ ratio
  const yiq = (red * 299 + green * 587 + blue * 114) / 1000

  return yiq >= 128
}

/**
 * @deprecated IVTS-69965
 */
export const colorOnBackground = (bkgColor = '#ffffff'): string => (isContrasted(bkgColor) ? BLACK : WHITE)

/**
 * @deprecated IVTS-69965
 */
export const backgroundUnderColor = (textColor = '#000000'): string => (isContrasted(textColor) ? BLACK : WHITE)

export const getFirstColorMeetingContrastGuidelines = (
  guideline: keyof ContrastScores,
  colorToCheck: string,
  ...colorsByPreference: string[]
): string => colorsByPreference.find((color) => meetsContrastGuidelines(colorToCheck, color)[guideline]) || ''
