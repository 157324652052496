import getNextConfig from 'next/config'

export type PublicRuntimeConfiguration = {
  basePath?: string
  frontEndBasePath?: string
  instanaKey?: string
  frontendApiURL?: string
  tealiumURL?: string
  didomiPublicKey?: string
  frontendPublicUrl?: string
  envType?: 'dev' | 'prod'
  abTastyURL?: string
  flagshipEnvId?: string
  flagshipApiKey?: string
  didomiSdkHostname?: string
  effinityTrackingUrl?: string
  applePaySdkURL?: string
  applePayApiVersion?: number
  criteoBaseUrl?: string
  criteoS2SId?: string
  googleWalletUrl?: string
  envName?: string
  datadogApplicationId?: string
  datadogClientToken?: string
  datadomeUrl?: string
}

export const getPublicRuntimeConfig = (): PublicRuntimeConfiguration => getNextConfig()?.publicRuntimeConfig || {}

type ServerRuntimeConfiguration = {
  backendApiURL?: string
  drupalApiURL?: string
  drupalApiKey?: string
}

export const getServerRuntimeConfig = (): ServerRuntimeConfiguration => getNextConfig()?.serverRuntimeConfig || {}
