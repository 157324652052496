import { FC, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { ThemeProvider } from '@mui/material/styles'
import { BookSdk, ItinerarySdk, TrackingSdk, User, UserSdk } from 'invictus-sdk-typescript'

import { DEFAULT_LOCALE } from '@Constants/locale'
import { FeaturesProvider } from '@Contexts/features/FeaturesContext'
import { SdkProvider } from '@Contexts/sdk/SdkContext'
import { useAllMessages } from '@Hooks/i18n'
import { darkTheme } from '@Material/theme'
import { Features } from '@Types/features'
import Locale from '@Types/locale'
import Market from '@Types/market'
import { formatToClientMarket } from '@Utils/market'
import { getDeviceSettings } from '@Utils/user'

import { getWidgetFrontendApiConfiguration } from '../config'

import CartButtonWidget from './CartButtonWidget'

export type CartButtonAppProps = {
  locale?: Locale
}

const apiConfiguration = getWidgetFrontendApiConfiguration()

const getUserSDK = (clientMarket: Market) => new UserSdk(new User(getDeviceSettings({ clientMarket })))

const getItinerarySdk = (userSdk: UserSdk) =>
  new ItinerarySdk(new TrackingSdk(apiConfiguration, userSdk), apiConfiguration, userSdk)

const getBookSDK = (userSdk: UserSdk, itinerarySdk: ItinerarySdk) =>
  BookSdk.init(itinerarySdk, getWidgetFrontendApiConfiguration(), userSdk)

const CartButtonApp: FC<CartButtonAppProps> = ({ locale = DEFAULT_LOCALE }) => {
  const { messages } = useAllMessages(locale)

  const userSdk = useMemo(() => getUserSDK(formatToClientMarket(locale)), [locale])
  const itinerarySdk = useMemo(() => getItinerarySdk(userSdk), [userSdk])
  const bookSdk = useMemo(() => getBookSDK(userSdk, itinerarySdk), [userSdk, itinerarySdk])

  return (
    // TODO (https://jira.vsct.fr/browse/IVTS-70505) : on initialise les features à vide pour le moment
    <FeaturesProvider features={{} as Features}>
      <ThemeProvider theme={darkTheme}>
        <IntlProvider locale={locale} defaultLocale={locale} messages={messages}>
          <SdkProvider customSdk={{ bookSdk }}>{messages && <CartButtonWidget />}</SdkProvider>
        </IntlProvider>
      </ThemeProvider>
    </FeaturesProvider>
  )
}

export const init = (containerId: string, locale?: Locale): void => {
  const root = createRoot(document.getElementById(containerId) as HTMLElement)

  root.render(<CartButtonApp locale={locale} />)
}
